import { BillingCurrency, Colors, Organisation } from "../organisation";
import { SelectedImage } from "../../components/form-controls/property-image-selector.dialog";
import { Property } from "../properties";
import { AdViews, Subscription } from "../subscriptions";

// #region interfaces

export interface AdAccount {
  name: string;
  actId: string;
  adAccountId: string;
}
export interface FacebookPages {
  pageId: string;
  pageLink: string;
  pageName: string;
  isDefault: boolean;
  user?: string;
}

export interface InstagramAccount {
  pageId: string;
  pageName: string;
  profilePic: string;
  isDefault: boolean;
}
export interface FacebookPixels {
  name: string;
  pixelId: string;
  isDefault: boolean;
  defaultConversion: string;
}

export interface FacebookAudience {
  id: string;
  audienceName: string;
  isDefault: boolean;
}

export interface ProductCatalogue {
  productCatalogueId: string;
  productCatalogueName: string;
  isDefault: boolean;
}
export interface AdAccountCampaigns {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  objective: string;
  configuredStatus: string;
  effectiveStatus: string;
  createdDate: string;
  updatedDate: string;
  buyingType: string;
}
export interface Iframe {
  adPreviewIframeContainer: string;
}
export interface CampaignAds {
  adId: string;
  adName: string;
  adSetId: string;
  productSetId: any;
  campaignId: string;
  adStatus: string;
  targeting: Targeting;
  adsetName: string;
  adsetStatus: string;
  selectedBrandObjectiveType: string;
  selectedBrandObjectiveTypeUrl: string;
  selectedFlowFuelObjectiveType: string;
  selectedFlowFuelObjectiveTypeUrl: string;
}
export interface FacebookAds {
  adCount: number;
  campaignAds: CampaignAds[];
}
export interface AgentUserDetails {
  profileImageUrl?: string;
  displayName?: string;
  email?: string;
  contactNumber?: string;
}
export interface FlowBrandOrFuel {
  id: string;
  name: string;
  objective: string;
  status: string;
  flowCampaignType: string;
  startDate: string;
  endDate: string;
  budget: number;
  spend: number;
  targetingDescription: string;
  goalDescription: number;
  reach?: number;
  frequency?: number;
  leadsCount?: number;
  profileViews?: number;
  totalAds: number;
  isExpanded: boolean;
  isFacebookCampaignImport: boolean;
  isDraft: boolean;
  agentUserDetails: AgentUserDetails;
  localCampaignId?: string;
  isProcessing?: boolean;
  error?: string;
}
export interface BudgetAndDays {
  campaignBudget: number;
  days: number;
}

export interface DashboardKpiCounts {
  uniquePeopleReached: number;
  buyerLeads: number;
  sellerLeads: number;
  recruitLeads: number;
  listingsProfileViews: number;
  campaignsCount: number;
}
export interface CustomLocations {
  distance_unit: string;
  latitude: number;
  longitude: number;
  radius: number;
  primary_city_id?: number;
  region_id?: any;
  country?: string;
  suburb?: string;
}
export interface City {
  country: string;
  distance_unit: string;
  key: string;
  name: string;
  radius: number;
  region: string;
  region_id: any;
}
export interface Region {
  country: string;
  key: string;
  name: string;
}

export interface Place {
  key: string;
  name: string;
  distance_unit: string;
  latitude: number;
  longitude: number;
  radius: number;
  primary_city_id: number;
  region_id: number;
  country: string;
}

export interface GeoLocation {
  custom_locations: CustomLocations[];
  location_types?: string[];
  cities?: City[];
  regions?: Region[];
  places?: Place[];
  customLocations?: CustomLocations[];
}
export interface Targeting {
  age_max?: any;
  age_min?: any;
  genders?: any[];
  geo_locations?: GeoLocation;
  targetingInterest?: string;
  brand_safety_content_filter_levels?: string[];
  targetingInterestDescription?: string;
  remainingRadiusTotal?: number;
  agemax?: string;
  agemin?: string;
  geoLocations?: GeoLocation;
}

export interface LabelValue {
  label: string;
  value: string;
  flag?: boolean;
  tooltipTitle?: string;
}
export interface listingsBeingPromoted {
  id: string;
  name: string;
  totalProperties: number;
  filter: any;
}
export interface AdMetaData {
  potentialReach: number;
  interest: string;
  retargeting: LabelValue[];
  listingsPromoted?: listingsBeingPromoted;
}

export interface onceOffMetaData {
  weeks: number;
  budgetPerWeek: number;
  billingCurrency: BillingCurrency;
  adViews: AdViews;
  starting: string;
}

export interface SelectedFlowCampaign {
  campaignGoalType: string;
  interest: string;
  selectedTemplate: Template | null;
  targeting: Targeting;
  budget: number;
  startDate: string;
  endDate: string;
  adAccountId: string;
  organisationId: string;
  campaignLocalId: any;
  facebookPageId: string;
  instagramPageId: string;
  campaignObjective: string;
  flowCampaignType: string;
  clientName: string;
  branch: string;
  commission: number;
  adCreative: AdCreative;
  adType?: string;
  screenStep: string;
  templateRender: TemplateRender;
  customCreative: CustomCreative | null;
  onceOffMetaData: onceOffMetaData;
  creditEntity: string;
  onceOffInitialWeeks: number;
  onceOffInitialBudgetPerWeek: number;
  onceOffInitialEndDate: string;
  total: number;
  isRecurring: boolean;
  currentSubscription: Subscription;
  pendingSubscription: Subscription;
  budgetPerWeek: BudgetAndDays | "custom";
  days: number;
  selectedBrandObjectiveType: string;
  selectedFlowFuelObjectiveType: string;
  setupType: string;
  owner: string;
  isDraft?: boolean;
  localCampaignId?: string;
  selectedFlowFuelObjectiveTypeUrl?: string;
  selectedBrandObjectiveTypeUrl?: string;
  externalRef?: string | null;
  facebookCampaignId?: string;
  propertyExternalId?: string | null;
  targetingTemplate?: TargetingTemplate | null;
  allowTargetingStepOverride?: boolean;
  isMasterCampaign?: boolean;
  isInMasterCampaignDraftMode?: boolean;
}

export interface AdCreative {
  creativeImageUrl?: string;
  htmlContent?: string;
  creativeName?: string; // Generated from backend
  adType?: string; //AdType
  message?: string; //mainDescription
  link?: string; // url to organisation profile / From Utils
  caption?: string; // Organisation website url
  name?: string; //Headline
  calltoAction?: string; // CAllToAction
  description?: string; //Links description
  propertyId?: string;
  type?: string;
  title?: string;
  thumbnail?: string;
  htmlURL?: string;
  templateDescription?: string;
  fieldsToMap?: FieldsToMap[];
  videoUrl?: string;
  headline?: string;
  agentId?: string;
  creativeImageBlob?: Blob;
  carouselMeta?: any[];
  isActive?: boolean;
  templateFields?: TemplateField[];
  templateColours?: ColourMapping[];
  templateCreativeType?: string;
  creatomateTemplateId?: string;
  templateId?: string;
  matchedPlaceholders?: Record<string, string[]>;
  placeholderValues?: Record<string, string>;
  facebookAdPreviewIframe?: string; // stringified iframe markup
  facebookAdPreviewUrl?: string;
}

export interface FlowCampaignStep {
  AdCreative?: string;
  Targeting?: string;
  Budget?: string;
  Publish?: string;
  CampaignStatus?: string;
  CampaignDates?: string;
  CampaignBudgetandDates?: string;
  Summary?: string;
  PropertySet?: string;
}

export interface AddCreateFlowState {
  canContinue: boolean;
  submitForm: boolean;
  doneUploading: boolean;
  hasError: boolean;
}

export interface AgeRange {
  label: string;
  min: number;
  max: number;
}

export interface credits {
  totalCredits: number;
}

export interface Template {
  readonly _id: string;
  type: string;
  title: string;
  description: string;
  htmlContent: string;
  thumbnail: string;
  campaignGoalType: string;
  campaignObjective: string;
  created?: string;
  updated?: string;
  fieldsToMap: FieldsToMap[];
  htmlURL: string;
  category?: string;
  organisation?: string;
  hasCustomCopy?: boolean;
  copyData?: {
    copyHeadline: string;
    copyMainDescription: string;
    copyDescription: string;
  };
  creativeType: string;
  templateFields?: TemplateField[];
  colourMappings?: ColourMapping[];
  creatomateTemplateId?: string;
  isNewTemplate?: boolean;
}

export interface FieldsToMap {
  readonly _id?: string;
  id: string;
  attribute: string;
  readonly defaultValue?: string;
  value?: string;
  isRequired?: boolean;
}

export interface AgentData {
  agentId?: string;
  agentAvatar?: string;
  agentName?: string;
  agentEmail?: string;
  agentContactNumber?: string;
  organisationName?: string;
  backgroundImage?: string;
  selectedProperty?: Property;
  defaultAgent?: any;
  propertyType?: string;
  suburb?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  province?: string;
  country?: string;
  postCode?: string;
  fullAddress?: string;
  location?: string;
  propertyStatus?: string;
  beds?: string;
  baths?: string;
  parking?: string;
  floorSize?: string;
  erfSize?: string;
  organisationLogo?: string;
  auctionStartDate?: string;
  auctionStartTime?: string;
  auctionEndDate?: string;
  auctionEndTime?: string;
  auctionStartPrice?: string;
  auctionVenueAddress?: string;
  auctionVenueName?: string;
  vehicleType?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleYear?: string;
  vehicleMileage?: string;
  vehicleFuelType?: string;
  vehicleEngine?: string;
  vehicleTransmission?: string;
  vehicleBodyType?: string;
  vehicleSeatingCapacity?: string;
  vehicleColor?: string;
  agentContactNumberUnformatted?: string;
}

export interface TemplateRender {
  setupType?: string;
  facebookAccountName?: string;
  testimonialText1?: string;
  clientName?: string;
  agentData?: AgentData;
  bragSituation?: string;
  isBudgetRequired?: boolean;
  isMainDescriptionUpdated?: boolean;
  isDescriptionUpdated?: boolean;
  isHeadlineUpdated?: boolean;
  adpostDefault?: AdpostDefault;
  facebook?: FacebookPages;
  location?: string;
  selectedTemplate?: Template;
  dateAndTime?: string;
  offer?: string;
  product?: string;
  storeDetail?: string;
  discountPercentage?: string;
  month?: string;
  year?: string;
  date?: string;
  time?: string;
  address?: string;
  percentSold?: string;
  rentalYield?: string;
  unitsLeft?: string;
  isHeadlineFaulty?: boolean;
}

export interface AdpostDefault {
  propertyType?: string;
  suburb?: string;
  propertyStatus?: string;
  beds?: string;
  baths?: string;
  agentName?: string;
  dateAndTime?: string;
  offer?: string;
  product?: string;
  storeDetail?: string;
}

export interface CampaignMode {
  campaignMode?: string; // this should be status
}

export interface DashboardCampaignsQuery {
  organisationId: string;
  owner?: string;
  status: CampaignState;
  startDate: string;
  endDate?: string;
  flowCampaignType?: CampaignGoalTypes;
}

export interface SocialState {
  readonly listLoading: boolean;
  createOrUpdateCampaignLoading: boolean;
  readonly dashboardKPILoading: boolean;
  readonly facebookAdsLoading: boolean;
  readonly iframeContainerLoading: boolean;
  readonly creditsLoading: boolean;
  errors?: string;
  readonly adAccounts: AdAccount[];
  readonly facebookPages: FacebookPages[];
  readonly instagramAccounts: InstagramAccount[];
  readonly productCatalogue: ProductCatalogue[];
  readonly facebookPixels: FacebookPixels[];
  readonly facebookAudiences: FacebookAudience[];
  readonly dashboardKpiCounts?: DashboardKpiCounts;
  readonly adAccountCampaigns: AdAccountCampaigns[];
  readonly isFacebookCampaignImport: boolean;
  readonly campaigns?: Record<string, FlowBrandOrFuel[]>;
  readonly facebookAds?: FacebookAds;
  readonly adPreviewIframeContainer?: Iframe;
  readonly advertMetaDataLoading: false;
  readonly advertMetaData?: AdMetaData;
  readonly selectedAd?: CampaignAds;
  readonly addCreateFlow?: AddCreateFlowState;
  selectedFlowCampaign?: SelectedFlowCampaign; //to create and edit the CMS campaign
  readonly credits?: credits;
  readonly templatesLoading: boolean;
  campaignTemplates?: GroupedAdTemplates;
  readonly campaignMetadataLoading: boolean;
  uploadingAdCreative: boolean;
  readonly uploadingCreativeVideoThumbnail: boolean;
  readonly campaignMode?: FlowBrandOrFuel;
  readonly pendingSubscriptionLoading: boolean;
  readonly isAddNewAd: boolean;
  dashboardCampaignsQuery?: DashboardCampaignsQuery;
  flowFuelCount: number;
  flowBrandCount: number;
  seenCampaigns: Record<string, boolean>;
  isRenderingTemplate: boolean;
  selectedProperties: string[];
  initializingCampaign: boolean;
  readonly clientLinkedFacebookPages: ClientLinkedFacebookPage[];
  isGeneratingCreativeImage: boolean;
  selectedImages: SelectedImage[];
  getAdTemplateByIdLoading: boolean;
  getTargetingTemplateByIdLoading: boolean;
  targetingTemplates: TargetingTemplate[];
  targetingTemplatesLoading: boolean;
}

export interface CreativeTypes {
  readonly TEMPLATE: "template";
  readonly IMAGE: string;
  readonly VIDEO: string;
}

export interface CustomCreative {
  creativeType: string;
  creativeUrl: string;
  adCreativeFile?: File | null;
  videoThumbnailBlob?: Blob | null;
  videoThumbnailUrl?: string | null;
  meetsDesignSpec?: boolean;
}

export interface AdTypes {
  SingleImage: string;
  LinkAd: string;
  VideoAd: string;
  CarouselAd: string;
}

export interface CampaignObjectives {
  BRANDAWARENESS: string;
  CONVERSIONS: string;
  LEADGENERATION: string;
  POSTENGAGEMENT: string;
  PRODUCTCATALOG: string;
  REACH: string;
  TRAFFICOBJECTIVE: string;
  VIDEOVIEWS: string;
}

export interface FlowCampaignTypes {
  FlowBrand: string;
  FlowFuelBuyers: string;
  FlowFuelRecruit: string;
  FlowFuelSellers: string;
}

export interface AdSet {
  targeting: Targeting;
  ads: AdCreative[];
}

export interface UploadAdCreativePayload {
  organisation: string;
  formData: FormData;
  creativeTypesEnum: CreativeTypes;
}

export interface ConnectClientFacebookPagesPayload {
  isMember: boolean;
  organisationId: string;
  userId: string;
  fbUserId: string;
  accessToken: string;
}

export interface ConnectClientFacebookPagesResponse {
  organisationSocialAssetPlusMemberPages: FacebookPages[];
  organisationPages: FacebookPages[];
  memberSpecificPages?: FacebookPages[];
  message?: string;
  organisation: Organisation;
}

export interface RemoveFbPagePayload {
  isMember: boolean;
  organisationId: string;
  userId: string;
  pageId: string;
}

export interface RemoveFbPageResponse {
  pageId: string;
  isDelinkedFromLocal: boolean;
  organisation: Organisation;
}

export interface PostSetupPrefillParams {
  propertyStatus: string;
  propertyType: string;
  propertySuburb: string;
  propertyAddressLine1: string;
  propertyAddressLine2: string;
  propertyCity: string;
  propertyProvince: string;
  propertyCountry: string;
  propertyPostCode: string;
  propertyFullAddress: string;
  propertyBedrooms: string;
  propertyBathrooms: string;
  agentName: string;
  dateAndTime: string;
  vehicleType: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleMileage: string;
  vehicleFuelType: string;
  vehicleEngine: string;
  vehicleTransmission: string;
  vehicleBodyType: string;
  vehicleSeatingCapacity: string;
  vehicleColor: string;
}

export interface TemplateGalleryTab {
  title: string;
  label: string;
  templates?: Record<TemplateTypes | AdTemplateCategories, Template[]>;
}

export interface CampaignLocalData {
  screenStep: CampaignSteps;
  adAccountId: string;
  setupType: SetupTypes;
  adType: AdTypesEnum;
  campaignGoalType: CampaignGoalTypes;
  facebookPageId: string;
  instagramPageId: string;
  clientProfile: string;
  selectedBrandObjectiveTypeUrl: string;
  selectedBrandObjectiveType: string;
  adsets: AdSet[];
}

export interface CampaignMetadata {
  campaignname: string;
}

export interface FlowCampaign {
  _id: string;
  buyingType: string;
  campaignlocaldata: CampaignLocalData;
  campaignmetadata: CampaignMetadata;
  campaignmode: string;
  channeltype: string;
  createdBy: string;
  facebookcampaignid: string;
  flowcampaigntype: FlowCampaignTypesEnum;
  isarchived: boolean;
  isfacebookcampaignimport: boolean;
  objective: CampaignObjectivesEnum;
  organisation: string;
  owner: string;
  status: string;
  updated: string;
  includesFbMetadata: boolean;
}

export interface RenderTemplatePayload {
  readonly adTemplateId: string;
  readonly modifications: Record<string, string>;
}

export interface GetCreditsResponse {
  readonly totalCredits: number;
}

export interface TemplateField {
  readonly key: string;
  readonly label: string;
  readonly inputType: InputTypes;
  readonly entity?: Entities;
  readonly entityField?: string;
  value?: any;
  readonly isRequired?: boolean;
  readonly index?: number;
}

export interface ColourMapping {
  readonly key: string;
  readonly property: string;
  readonly themeColour: string;
  readonly value?: string;
}

export interface SaveDraftCampaignStepPayload {
  readonly screenStep: string;
  readonly nextStep: string;
}

export interface GenerateCreativeImageRequestPayload {
  adTemplateUrl: string;
  fieldsToMap: FieldsToMap[];
  colors?: Colors;
}

export interface CostPerImpression {
  campaignType: CampaignGoalTypes;
  high: number;
  low: number;
}

export interface TargetingInterestItem {
  id: string;
  name: string;
}

export interface Audiences {
  NONE: string;
  AUDIENCE_ID: string;
  CUSTOM_AUTO_ORG: string;
  CUSTOM_AUTO_SUBURB: string;
}

export interface GeoTargetingTypes {
  RADIUS: string;
  NEARBY_SUBURBS: string;
}

export interface DistanceUnit {
  Kilometer: string;
  Mile: string;
}

export interface FacebookTargetingInterstRegionSet {
  id: string; 
  name: string;
}

export interface behavioralProfile{
  name: string;
  targetingInterestType : string; 
  facebookTargetingInterestRegionSet: FacebookTargetingInterstRegionSet[];
}

interface FacebookMetadata {
  gender: number[];
  ageMax: string; 
  ageMin: string;
}

export interface demographicProfile{
  facebookMetadata: FacebookMetadata
}

export interface AudienceData{
  id: string,
  name: string,
  approximate_count_lower_bound: number,
  approximate_count_upper_bound: number
}

export interface TargetingTemplate {
  name: string;
  organisation?: string;
  isEnabled: boolean;
  channel: ChannelTypes;
  adType: AdTypesEnum;
  objective: CampaignObjectivesEnum;
  optimizationGoal: string;
  facebookTargeting: {
    facebookPixelId?: string;
    facebookPixelConversion?: string;
    translationLanguage?: string;
  };
  audiences: string;
  audienceId: string;
  enableBehavioralTarget: boolean;
  behavioralProfileId?: string | behavioralProfile;
  demographicProfileId?: string | demographicProfile;
  enableGeoTargeting: boolean;
  geoTargetingLogic: string;
  geoTargetingUnits: string;
  geoTargetingRadius?: number;
  allowOverride: boolean;
  audienceData?: AudienceData[];
}

// #endregion

// #region enums

export enum CampaignState {
  All = "All",
  Active = "Active",
  Archived = "Archived",
  Draft = "Draft",
  Deleted = "Deleted",
  InProcess = "InProcess",
  Paused = "Paused",
  WithIssues = "WithIssues",
}

export enum CampaignSteps {
  AdCreative = "AdCreative",
  Targeting = "Targeting",
  Summary = "Summary",
}

export enum TargetingInterests {
  AllPeople = "All people",
  Buyers = "Buyers",
  Tenants = "Tenants",
}

export enum TargetingInterestsDescriptions {
  AllPeople = "Your ads will be shown to all the people in the specified area, age and gender. Recommended when promoting your profile and brand so you can remain top of mind to everyone while they consider selling or buyer their property.",
  Buyers = "Your ads will be shown to people who are likely property buyers in the market now. Flow picks this up with interest and behaviour targeting of people who are browsing property content, mortgage calculators and related services.",
  Tenants = "Your ads will be shown to people who are likely tenants in the market now looking to rent. Flow picks this up with interest and behaviour targeting of people who are browsing property content, related services and other rental behaviours.",
}

export enum CampaignGoalTypes {
  FlowBrand = "FlowBrand",
  FlowFuel = "FlowFuel",
}

export enum CallToActionKeys {
  ContactUs = "CONTACT_US",
  LearnMore = "LEARN_MORE",
}

export enum CallToActionValues {
  ContactUs = "Contact Us",
  LearnMore = "Learn More",
}

export enum SpecialAdCategoryTypes {
  Credit = "CREDIT",
  Employment = "EMPLOYMENT",
  Housing = "HOUSING",
  IssuesElectionsPolitics = "ISSUES_ELECTIONS_POLITICS",
  None = "NONE",
}

export enum BrandObjectiveTypeParent {
  LandingPage = "Automated Landing Page",
  CustomLink = "Custom Link",
  TrackedCustomLink = "Custom Link with Tracking",
  LeadForm = "Lead Form",
}

export enum FlowCampaignTypesEnum {
  FlowBrand = "FlowBrand",
  FlowFuelBuyers = "FlowFuelBuyers",
  FlowFuelRecruit = "FlowFuelRecruit",
  FlowFuelSellers = "FlowFuelSellers",
}

export enum AdTemplateCategories {
  BrandPromotion = "Brand Promotion",
  DevelopmentAwareness = "Development - Awareness",
  DevelopmentLaunch = "Development - Launch",
  DevelopmentPreSales = "Development - Pre Sales",
  FestiveSeason = "Festive Season",
  GeneralSalePromotion = "General Sale Promotion",
  NewProducts = "New Products",
  OnShow = "On Show",
  ProductOffers = "Product Offers",
  StoreLaunch = "Store Launch",
  FinancialServices = "Financial Services",
}

export enum PrefillTextDefaults {
  AgentName = "[Agent Name]",
  PropertyType = "[Property Type]",
  Suburb = "[Suburb]",
  ForSale = "[FOR SALE]",
  Beds = "[Beds]",
  Baths = "[Baths]",
  DateAndTime = "[DateAndTime]",
  Offer = "[Offer]",
  Product = "[Product]",
  StoreDetail = "[Store detail]",
}

export enum AdStatuses {
  Active = "ACTIVE",
  Paused = "PAUSED",
  Archived = "ARCHIVED",
  PendingReview = "PENDING_REVIEW",
  AdSetPaused = "ADSET_PAUSED",
  InProcess = "IN_PROCESS",
  Processing = "PROCESSING",
}

export enum TemplateTypes {
  PromoteListing = "Promote Listing",
  SoldStock = "Sold Stock",
  AreaSpecialist = "Area Specialist",
  Testimonial = "Testimonial",
  DynamicCarousel = "Dynamic Carousel",
  LeadGeneration = "Lead Generation",
}

export enum CampaignObjectivesEnum {
  BrandAwareness = "BRANDAWARENESS",
  Conversions = "CONVERSIONS",
  LeadGeneration = "LEADGENERATION",
  PostEngagement = "POSTENGAGEMENT",
  ProductCatalog = "PRODUCTCATALOG",
  Reach = "REACH",
  TrafficObjective = "TRAFFICOBJECTIVE",
  VideoViews = "VIDEOVIEWS",
}

export enum AdTypesEnum {
  SingleImage = "SingleImage",
  LinkAd = "LinkAd",
  VideoAd = "VideoAd",
  CarouselAd = "CarouselAd",
  LeadAd = "LeadAd",
}

export enum SetupTypes {
  BLP_AB = "BLP_ab",
}

export enum Genders {
  All = 0,
  Male = 1,
  Female = 2,
}

export enum LocationTypes {
  Home = "home",
  Recent = "recent",
}

export enum PostSetupPrefillPlaceholders {
  PropertyStatus = "[FOR SALE]",
  PropertyType = "[Property Type]",
  PropertySuburb = "[Suburb]",
  PropertyBedrooms = "[Beds]",
  PropertyBathrooms = "[Baths]",
  AgentName = "[Agent Name]",
}

export enum BrandObjectiveTypes {
  LandingPage = "Automated Landing Page",
  LeadForm = "Lead Form",
}

export enum FlowFuelObjectiveTypes {
  LandingPage = "Automated Landing Page",
  LeadForm = "Lead Form",
}

export enum AdTemplateGroups {
  Private = "private",
  Public = "public",
  PublicVideoTemplates = "public_video_templates",
  PrivateVideoTemplates = "private_video_templates",
}

export enum CreativeTypesEnum {
  Image = "image",
  Video = "video",
  Template = "template",
}

export enum CampaignStepsIds {
  AdBuilder = "ad-builder",
  Targeting = "targeting",
  Summary = "summary",
}

export enum CampaignStepsTitles {
  AdBuilder = "Ad Builder",
  Targeting = "Targeting",
  Summary = "Summary",
}

export enum CampaignStepsDescriptions {
  AdBuilder = "Let’s create an ad for you from your content, no need for a designer.",
  Targeting = "Select who should be seeing your ads. You can choose ages, genders and the areas where you operate.",
  FuelSummary = "Here’s an overview of your campaign. Select the number of weeks to promote your ad and the weekly budget. If all looks correct click Purchase and go live, or click previous to make changes.",
  BrandSummary = "Here’s an overview of your campaign. Select the monthly package that works for you to keep top-of-mind (it auto-renews monthly and you can cancel anytime). If all looks correct click Purchase and go live, or click previous to make changes.",
}

export enum InputTypes {
  Address = "address",
  Agent = "agent",
  Derived = "derived",
  File = "file",
  Organisation = "organisation",
  Property = "property",
  Text = "text",
}

export enum Entities {
  Agent = "agent",
  Organisation = "organisation",
  Property = "property",
  Address = "address",
}

export enum PropertyDerivedPrefillPlaceholders {
  Address = "[property.address]",
  Suburb = "[property.suburb]",
  AddressLine1 = "[property.addressLine1]",
  AddressLine2 = "[property.addressLine2]",
  City = "[property.city]",
  Province = "[property.province]",
  Country = "[property.country]",
  PostCode = "[property.postCode]",
  Bathrooms = "[property.bathrooms]",
  Bedrooms = "[property.bedrooms]",
  Parkings = "[property.parkings]",
  Price = "[property.price]",
  ErfSize = "[property.erfSize]",
  FloorSize = "[property.floorSize]",
  ListingStatus = "[property.listingStatus]",
  VehicleType = "[property.vehicleType]",
  VehicleMake = "[property.vehicleMake]",
  VehicleModel = "[property.vehicleModel]",
  VehicleYear = "[property.vehicleYear]",
  VehicleMileage = "[property.vehicleMileage]",
  VehicleFuelType = "[property.vehicleFuelType]",
  VehicleEngine = "[property.vehicleEngine]",
  VehicleTransmission = "[property.vehicleTransmission]",
  VehicleBodyType = "[property.vehicleBodyType]",
  VehicleSeatingCapacity = "[property.vehicleSeatingCapacity]",
  VehicleColor = "[property.vehicleColor]",
}

export enum AddressDerivedPrefillPlaceholders {
  Suburb = "[address.suburb]",
}

export enum AgentDerivedPrefillPlaceholders {
  FirstName = "[agent.firstName]",
  LastName = "[agent.lastName]",
  DisplayName = "[agent.displayName]",
  Email = "[agent.email]",
  ContactNumber = "[agent.contactNumber]",
}

export enum ChannelTypes {
  Facebook = "facebook",
  Google = "google",
  Twitter = "twitter",
  Tiktok = "tiktok",
  Linkedin = "linkedin",
  Dooh = "dooh",
}

export enum DerivedFieldKeys {
  OrganisationLogo = "organisationLogo",
  OrganisationName = "organisationName",
  AgentName = "agentName",
  AgentEmail = "agentEmail",
  AgentContactNumber = "agentContactNumber",
  AgentContactNumberUnformatted = "agentContactNumberUnformatted",
  AgentAvatar = "agentAvatar",
  PropertyPrice = "propertyPrice",
  PropertyStatus = "propertyStatus",
  PropertyType = "propertyType",
  Suburb = "suburb",
  Beds = "beds",
  Baths = "baths",
  BackgroundImage = "backgroundImage",
  ListingImage = "listing.image",
}

export enum KnowManualFields {
  BragSituation = "bragSituation",
  Location = "location",
  TestimonialText1 = "testimonialText1",
  TestimonialText2 = "testimonialText2",
  ClientName = "clientName",
  TestimonialExcerpt = "testimonialExcerpt",
  Offer = "offer",
  Product = "product",
  StoreDetail = "storeDetail",
  DiscountPercentage = "discountPercentage",
  DateAndTime = "dateAndTime",
  Month = "month",
  Year = "year",
  Date = "date",
  Time = "time",
  PercentSold = "percentSold",
  RentalYield = "rentalYield",
  UnitsLeft = "unitsLeft",
}

// #endregion

export interface LinkSelectedClientFacebookPagesPayload {
  isMember: boolean;
  organisationId: string;
  userId: string;
  fbUserId: string;
  accessToken: string;
  selectedClientPages: ClientLinkedFacebookPage[];
}

// #region types

export type ApiResponse = Record<string, any>;

export type PostSetupParams = Required<
  Pick<AdCreative, "name" | "message" | "description">
>;

export type GroupedAdTemplates = Record<
  AdTemplateGroups,
  Record<TemplateTypes | AdTemplateCategories, Template[]>
>;

export type TemplateCreativeTypes = Omit<CreativeTypesEnum, "Template">;

export type GetCreditsPayload =
  | Record<"agent", string>
  | Record<"organisation", string>;

// #endregion

export interface ClientLinkedFacebookPage {
  pageId: string;
  pageName: string;
  pageAccessToken: string;
  accessToken: string;
}

export interface GetLinkedFacebookPagesByUserTokenPayload {
  fbUserId: string;
  accessToken: string;
  organisationId: string;
}

export interface CampaignDetails {
  id: string;
  date: string;
  activityDetails: string;
  itemChanged?: string;
  changedBy: string;
}
