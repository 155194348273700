import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from ".";

const FolderIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon viewBox="0 0 24 24" {...props}>
    <svg width={24} height={24} {...props}>
      <path
        d="M3 19V6a1 1 0 0 1 1-1h4.032a1 1 0 0 1 .768.36l1.9 2.28a1 1 0 0 0 .768.36H16a1 1 0 0 1 1 1v1M3 19l3-8h15l-3 8H3Z"
        stroke={nativeColor}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  </MuiSvgIcon>
);

export default FolderIcon;
