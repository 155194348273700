import React, { useState } from "react";
import Avatar from "../avatars/UserAvatar";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { AuthenticatedUser } from "../../store/auth";
import styled from "styled-components";
import { Box, makeStyles, MenuItem, Popover } from "@material-ui/core";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { Organisation } from "../../store/organisation";

const ComponentWrapper = styled.div``;
const AvatarWrapper = styled.div`
  display: block;
  cursor: pointer;
`;

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    marginTop: 5,
    width: 200,
    position: "relative",
    padding: theme.spacing(1),
  },
}));

export interface PropsFromState {
  user?: AuthenticatedUser;
  organisation?: Organisation;
}

export interface PropsFromDispatch {}
export interface OwnProps {
  OnHelpClicked: () => void;
  OnLogoutClicked: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

const UserMenu: React.FC<AllProps> = ({
  user,
  OnHelpClicked,
  OnLogoutClicked,
  organisation,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const billingAndWalletEnabled = get(
    organisation,
    "settings.paymentMethods.wallet",
    false
  ); //||

  const open = Boolean(anchorEl);
  const id = open ? "profile-menu-popover" : undefined;

  return (
    <ComponentWrapper>
      <AvatarWrapper onClick={handleOpen}>
        <Avatar
          displayName={user ? `${user.firstName} ${user.lastName}` : ""}
          profileImage={
            user && user.profileImage && user.profileImage.publicUrl
              ? user.profileImage.publicUrl
              : ""
          }
        />
      </AvatarWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        {/* Menu Items */}
        <Box>
          <Link to="/profile">
            <MenuItem onClick={handleClose}>My Profile</MenuItem>
          </Link>

          {billingAndWalletEnabled && (
            <Link to="/billing-and-wallet">
              <MenuItem onClick={handleClose}>Wallets & Payments</MenuItem>
            </Link>
          )}
          <MenuItem
            onClick={() => {
              OnHelpClicked();
              handleClose();
            }}
          >
            Help
          </MenuItem>
          <MenuItem
            onClick={() => {
              OnLogoutClicked();
              handleClose();
            }}
          >
            Log out
          </MenuItem>
        </Box>
      </Popover>
    </ComponentWrapper>
  );
};

const mapStateToProps = ({
  auth,
  organisation,
}: ApplicationState): PropsFromState => ({
  user: auth.user,
  organisation: organisation.organisation,
});

export default connect<
  PropsFromState,
  PropsFromDispatch,
  OwnProps,
  ApplicationState
>(
  mapStateToProps,
  {}
)(UserMenu);
